/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //FB Like
        $.each($('.fb-like'), function (i, el) {
          $(el).hover(function() {
            $(el).find($('.fb-box')).stop().slideToggle();
            $(el).addClass('active');
          }, function() {
            $(el).find($('.fb-box')).stop().slideToggle();
            $(el).removeClass('active');
          });
        });

        // Mobile Menu
        $('.fa-bars').click(function(){
          $('.mobile-nav').slideToggle();
          $('.mobile-menu-wrap').toggleClass('active');
          $('.fa-bars').toggleClass('active');
        });

        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }

      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },

    // Home page
    'home': {
      init: function() {

        $(function() {
          $('.slideshow-content').addClass('active');
        });

        var slides = $('.slideshow').data('slides');
        $('.slideshow').backstretch(slides, { fade: 1000, duration: 8000 });

        $(window).on("backstretch.before", function (e, instance, index) {
          $('.slide-content.active').removeClass('active');
          $('#slide-content-' + index).addClass('active');
          $('#slide-content-mobile-' + index).addClass('active');
        });

        $("#previous").click(function(e) {
          e.preventDefault();
          $('.slideshow').backstretch("prev");
        });

        $("#next").click(function(e) {
          e.preventDefault();
          $('.slideshow').backstretch("next");
        });

        $(window).on('load resize', function() {
          if (window.innerWidth < 768) {
            $('.home-banner__content').css('height', 'auto');
            return;
          }

          $('.home-banners').equalize({
            children: '.home-banner__content',
            reset: true,
            equalize: 'outerHeight'
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {

        //if($(window).width() > 767) {
        //  $('.columns-2').columnize({ width : 900, columns : 2 });
        //}

      }
    },

    //Ingredients
    'post_type_archive_ingredients': {
      init: function() {

        $('.als-ingredient-wrap').click(function(){
           $('.als-ingredient-wrap').not(this).removeClass('active').find('p').slideUp();
           $(this).toggleClass('active').find('p').slideToggle();
        });

      }
    },

    //Products Archive
    'post_type_archive_products': {
      init: function() {

        //Change the accordion icon
        $('.collapse')
        .on('shown.bs.collapse', function() {
         $(this).parent().find(".fa-chevron-down, .fa-chevron-up").toggleClass("rotate");
         $(this).parent().find(".nano").nanoScroller({ alwaysVisible: true, scroll: 'top' });
        })
        .on('hidden.bs.collapse', function() {
         $(this).parent().find(".fa-chevron-down, .fa-chevron-up").toggleClass("rotate");
        });

        // Change height without click:
        $(window).on('load resize', function () {
          var $this = $('.als-product-main-wrap.active').closest('.als-product-main-wrap');
          var product_content = $this.find('.als-product-content').height();
          var product_headings = $this.find('.product-headings').outerHeight(true);
          var als_product_footer = $this.find('.als-product-footer').height();
          var panel_heading = $this.find('.panel-heading').outerHeight(true);
          var panel_heading_3 = panel_heading*3;
          $('.panel-body')
            .css({'height': (product_content - product_headings - panel_heading_3 - als_product_footer - 20)
          });
        });

        //Products
        $(".als-product-link-wrap").click(function () {
          $('.als-product-main-wrap.active').not($(this).closest('.als-product-main-wrap')).removeClass('active');
          $(this).closest('.als-product-main-wrap').toggleClass('active');

          // History stuff
          var url = $(this).data('url');
          var title = $(this).data('title');
          History.replaceState({state:1}, title, url);

          // Change height
          var $this = $(this).closest('.als-product-main-wrap');
          var product_content = $this.find('.als-product-content').height();
          var product_headings = $this.find('.product-headings').outerHeight(true);
          var als_product_footer = $this.find('.als-product-footer').height();
          var panel_heading = $this.find('.panel-heading').outerHeight(true);
          var panel_heading_3 = panel_heading*3;
          //console.log(product_content,product_headings,panel_heading_3,als_product_footer);
          $('.panel-body')
            .css({'height': (product_content-product_headings-panel_heading_3-als_product_footer-20)
          });

          // Custom Scrollbar
          $('.panel-body .nano').nanoScroller({ alwaysVisible: true, scroll: 'top' });

        });

        $(".fa-times").click(function () {
          $(this).parents().find('.als-product-main-wrap').removeClass('active');
        });

      }
    },


    //  Products Single
    'single_products': {
      init: function() {

        //Change the accordion icon
        $('.collapse')
        .on('shown.bs.collapse', function() {
         $(this).parent().find(".fa-chevron-down, .fa-chevron-up").toggleClass("rotate");
         $(this).parent().find(".nano").nanoScroller({ alwaysVisible: true, scroll: 'top' });
        })
        .on('hidden.bs.collapse', function() {
         $(this).parent().find(".fa-chevron-down, .fa-chevron-up").toggleClass("rotate");
        });

        // Scroll to the active product
        $('html, body').animate({scrollTop: $('.als-product-main-wrap.active').offset().top}, 'slow');

        // Change height without click:
        $(window).on('load resize', function () {
          var $this = $('.als-product-main-wrap.active').closest('.als-product-main-wrap');
          var product_content = $this.find('.als-product-content').height();
          var product_headings = $this.find('.product-headings').outerHeight(true);
          var als_product_footer = $this.find('.als-product-footer').height();
          var panel_heading = $this.find('.panel-heading').outerHeight(true);
          var panel_heading_3 = panel_heading*3;
          $('.panel-body')
            .css({'height': (product_content-product_headings-panel_heading_3-als_product_footer-20)
          });
        });


        //  OnClick Function:
        $(".als-product-link-wrap").click(function () {

          $('.als-product-main-wrap.active').not($(this).closest('.als-product-main-wrap')).removeClass('active');
          $(this).closest('.als-product-main-wrap').toggleClass('active');

          // History stuff
          var url = $(this).data('url');
          var title = $(this).data('title');
          History.replaceState({state:1}, title, url);

          // Change height
          var $this = $(this).closest('.als-product-main-wrap');
          var product_content = $this.find('.als-product-content').height();
          var product_headings = $this.find('.product-headings').outerHeight(true);
          var als_product_footer = $this.find('.als-product-footer').height();
          var panel_heading = $this.find('.panel-heading').outerHeight(true);
          var panel_heading_3 = panel_heading*3;

          $('.panel-body')
            .css({'height': (product_content-product_headings-panel_heading_3-als_product_footer-20)
          });

        });

        $('.panel-body .nano').nanoScroller({ alwaysVisible: true, scroll: 'top' });

        $(".fa-times").click(function () {
          $(this).parents().find('.als-product-main-wrap').toggleClass('active');
        });


      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
